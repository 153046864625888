const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_form`);

// 处理资质单图片上传接口文档地址：http://219.128.52.2:9999/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/images-controller/aptitudeSingleImageUsingPOST
const web_common_upload_aptitudeSingle = (pParameter) => {

  if (!pParameter) pParameter = {};


  const formData = new FormData();
  formData.append('fileUpload', pParameter);
  return __request({
    urlSuffix: '/web/common/upload/aptitudeSingle',
    data: formData,
    transformRequest: [function (data, headers) {
      return data;
    }],
  });

};
module.exports = web_common_upload_aptitudeSingle;
