// 公司信息管理
import Q from "q";
// 获取账号信息
import web_userBuyer_find from "@/lib/data-service/default/web_userBuyer_find";
// 上传照片
import web_common_upload_aptitudeSingle from "@/lib/data-service/default/web_common_upload_aptitudeSingle";
// 城市地区接口
import web_common_area_getInlandAreaChildListByParentIdResponse from "@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentIdResponse";
// 修改公司信息
// import web_userSupplier_update from "@/lib/data-service/default/web_userSupplier_update";
import web_userBuyer_update from "@/lib/data-service/default/web_userBuyer_update";
// web_userBuyer_update
// 验证原始密码
import web_common_user_checkUserPassword from "@/lib/data-service/default/web_common_user_checkUserPassword";
// 修改密码
import web_common_user_updateUserPassword from "@/lib/data-service/default/web_common_user_updateUserPassword";
import { valid } from "semver";


export default {
  components: {},
  data() {
    var validateVerify = (rule, value, callback) => {
      if (value === undefined || value === "") {
        callback(new Error("请确认密码"));
      } else {
        if (value !== this.passwordForm.password) {
          callback(new Error("两次密码不一致"));
        }
        callback();
      };
    };

    var validateArea = (rule, value, callback) => {
      if (this.form.areaRegion === undefined || this.form.areaRegion === '') {
        callback(new Error("请选择地区"));
      } else if (this.form.areaProvince === undefined || this.form.areaProvince === '') {
        callback(new Error("请选择省份"));
      } else if (this.form.areaCity === undefined || this.form.areaCity === '') {
        callback(new Error("请选择城市"));
      }
      callback();
    };

    var validateBusinessLicense = (rule, value, callback) => {
      if (value === undefined || value === '') {
        callback(new Error("请上传营业执照照片"));
      } else {
        callback();
      }
    };

    var validateIdCardFront = (rule, value, callback) => {
      if (value === undefined || value === '') {
        callback(new Error("请上传法人证件正面照片"));
      } else {
        callback();
      }
    };

    var validateIdCardReverse = (rule, value, callback) => {
      if (value === undefined || value === '') {
        callback(new Error("请上传法人证件反面照片"));
      } else {
        callback();
      }
    };

    var validateOldPassword = (rule, value, callback) => {
      if (value === undefined || value === '') {
        callback(new Error("请输入原始密码"));
      } else {
        if (value.length < 6) {
          callback(new Error("最少要输入6个字符"));
        } else if (value.length > 20) {
          callback(new Error('最多要可输入20个字符'));
        } else {
          const _this = this;
          const params = {
            id: _this.form.uId,
            password: _this.passwordForm.oldPassword,
          }
          Q.when()
            .then(function () {
              return _this.req_verify_oldPassword(params);
            })
            .then(function (res) {
              if (res.checkBoolean === true) {
                callback();
              } else if (res.checkBoolean === false) {
                callback(new Error("原始密码不正确"));
              }
            })
            .catch(function () {
              callback(new Error("原始密码不正确"));
            })
        }
      }
    };


    return {
      form: {
        bId: '',
        apId: '',
        uId: '',
        userName: "",
        buyerStatusText: "",
        phone: "",
        buyerTypeText: "",
        companyName: '',
        areaRegion: '',
        areaProvince: '',
        areaCity: '',
        companyAddress: '',
        financeFax: '',
        businessLicenseNum: '',
        businessLicensePic: '',
        idCard: '',
        idCardFront: '',
        idCardReverse: '',
      },
      samplePic: '',
      areaRegionList: [],
      areaProvinceList: [],
      areaCityList: [],
      formRules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        area: [
          { validator: validateArea, trigger: "blur", required: true }
        ],
        companyAddress: [
          { required: true, message: '请输入公司地址', trigger: 'blur' }
        ],
        // financeFax: [
        //   { required: true, message: '请输入传真号', trigger: 'blur' },
        //   { pattern: /\d/, message: '请输入正确的传真号', trigger: 'blur' }
        // ],
        businessLicenseNum: [
          { required: true, message: '请输入营业执照号', trigger: 'blur' }
        ],
        businessLicensePic: [
          { validator: validateBusinessLicense, trigger: "blur", required: true }
        ],
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号', trigger: 'blur' },
        ],
        // idCardFront: [
        //   { validator: validateIdCardFront, trigger: "blur", required: true }
        // ],
        // idCardReverse: [
        //   { validator: validateIdCardReverse, trigger: "blur", required: true }
        // ],
      },
      changePasswordAdialog: false,
      passwordForm: {
        oldPassword: '',
        password: "",
        verifyPassword: ""
      },
      passwordRules: {
        oldPassword: [
          { validator: validateOldPassword, trigger: "blur", required: true }
          // { required: true, message: "请输入新密码", trigger: "blur" },
          // { min: 6, max: 20, message: "请设置6到20个字符", trigger: "blur" },
          // {
          //   pattern: /[0-9a-zA-Z]/,
          //   message: "仅限使用数字与字母",
          //   trigger: "blur"
          // }
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, max: 20, message: "请设置6到20个字符", trigger: "blur" },
          {
            pattern: /[0-9a-zA-Z]/g,
            message: "仅限使用数字与字母",
            trigger: "blur"
          }
        ],
        verifyPassword: [
          { validator: validateVerify, trigger: "blur", required: true }
        ]
      }
    };
  },
  computed: {},
  methods: {
    req_info() {
      const _this = this;
      Q.when()
        .then(function () {
          return web_userBuyer_find();
        })
        .then(function (res) {
          _this.form.bId = res.bId;
          _this.form.apId = res.apId;
          _this.form.uId = res.uId;
          _this.form.phone = res.phone;
          _this.form.companyName = res.companyName;
          _this.form.areaRegion = res.areaRegion;
          _this.form.areaProvince = res.areaProvince;
          _this.form.areaCity = res.areaCity;
          _this.form.companyAddress = res.companyAddress;
          _this.form.financeFax = res.financeFax;
          _this.form.businessLicenseNum = res.businessLicenseNum;
          _this.form.businessLicensePic = res.businessLicensePic;
          _this.form.idCard = res.idCard;
          _this.form.idCardFront = res.idCardFront;
          _this.form.idCardReverse = res.idCardReverse;


          _this.form.userName = res.userName;
          _this.form.buyerStatusText = res.buyerStatusText;
          _this.form.buyerTypeText = res.buyerTypeText;
          _this.get_area_city(_this.form.areaRegion, _this.form.areaProvince, _this.form.areaCity);
        });
    },
    req_area_data(id = 100000) {
      return Q.when()
        .then(function () {
          return web_common_area_getInlandAreaChildListByParentIdResponse({ id });
        })
    },
    req_upload_img(params) {
      return Q.when()
        .then(function () {
          return web_common_upload_aptitudeSingle(params);
        })
    },
    req_change_info(params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_userBuyer_update(params);
        })
        .then(function (res) {
          _this.$message({
            type: 'success',
            message: '修改信息成功',
            offset: 100
          })
          _this.initialize();
        })
    },
    req_verify_oldPassword(params) {
      return Q.when()
        .then(function () {
          return web_common_user_checkUserPassword(params);
        })
    },
    req_change_passwrod(params) {
      return Q.when()
        .then(function () {
          return web_common_user_updateUserPassword(params);
        })
    },
    initialize() {
      this.req_info();
    },
    get_area_city(areaRegion, areaProvince) {
      const _this = this;
      Q.when()
        .then(function () {
          return _this.req_area_data();
        })
        .then(function (res) {
          _this.areaRegionList = res.areaTreeResult;
        });

      _this.change_areaRegion(areaRegion, 'initialize');
      _this.change_areaProvince(areaProvince, 'initialize');
    },
    to_change_password() {
      this.changePasswordAdialog = true;
    },
    handleClosePassword() {
      this.passwordForm = {
        oldPassword: '',
        password: '',
        verifyPassword: ''
      };
      this.changePasswordAdialog = false;
    },
    commit_password() {
      const _this = this;
      _this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          const params = {
            id: _this.form.uId,
            password: _this.passwordForm.password,
          }
          Q.when()
            .then(function () {
              return _this.req_change_passwrod(params);
            })
            .then(function (res) {
              _this.changePasswordAdialog = false;
              _this.passwordForm = {
                oldPassword: '',
                password: '',
                verifyPassword: ''
              };
              _this.$confirm
              _this.$confirm('修改成功，请重新登录', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                showCancelButton: false,
                type: 'warning'
              }).then(() => {
                _this.$router.replace({name: 'login'});
              }).catch(() => {
                _this.$router.replace({name: 'login'});   
              });
            })
        }
      })
    },
    cancel_password() {
      this.passwordForm = {
        oldPassword: '',
        password: '',
        verifyPassword: ''
      };
      this.changePasswordAdialog = false;
    },
    change_areaRegion(value, type) {
      if (value === '900000') return;
      const _this = this;
      if (!type) {
        _this.form.areaProvince = '';
        _this.form.areaCity = '';
        _this.areaCityList = [];
      };
      Q.when()
        .then(function () {
          return _this.req_area_data(value);
        })
        .then(function (res) {
          _this.areaProvinceList = res.areaTreeResult;
        })
    },
    change_areaProvince(value, type) {
      if (value === '900000') return;
      const _this = this;
      if (!type) _this.form.areaCity = '';
      Q.when()
        .then(function () {
          return _this.req_area_data(value);
        })
        .then(function (res) {
          _this.areaCityList = res.areaTreeResult;
        })
    },
    select_file(node) {
      const _this = this;
      const file = _this.$refs[node].files[0];
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        _this.$message({
          type: 'error',
          message: '上传图片大小不能超过 10MB!',
          offset: 100
        });
        return;
      }

      Q.when()
        .then(function () {
          return _this.req_upload_img(file);
        })
        .then(function (res) {
          _this.form[node] = res.fileList[0];

          _this.$message({
            type: 'success',
            message: '文件上传成功',
            offset: 100
          });
        })
    },
    commit_info() {
      const _this = this;
      _this.$refs.form.validate((valid) => {
        if (valid) {

          const form = _this.form;
          const params = {};
          params.bId = form.bId;
          params.apId = form.apId;
          params.uId = form.uId;
          params.businessLicenseNum = form.businessLicenseNum;
          params.businessLicensePic = form.businessLicensePic;
          params.idCard = form.idCard;
          params.idCardFront = form.idCardFront;
          params.idCardReverse = form.idCardReverse;
          params.phone = form.phone;
          params.areaRegion = form.areaRegion;
          params.areaProvince = form.areaProvince;
          params.areaCity = form.areaCity;
          params.companyName = form.companyName;
          params.companyAddress = form.companyAddress;
          params.financeFax = form.financeFax;
          _this.req_change_info(params);
        }
      })
    }
  },
  watch: {
    form: {
      handler: function (newVal) {
        if (newVal.areaRegion === '900000') {
          const _this = this;
          _this.areaRegionList.forEach(function (item) {
            if (item.id === 900000) {
              const arr = [];
              arr[0] = item;
              _this.areaProvinceList = arr;
              _this.areaCityList = arr;
            }
          })
          this.form.areaProvince = '900000';
          this.form.areaCity = '900000';
        }
      },
      deep: true,
    }
  },
  mounted() { },
  activated() {
    this.initialize();
  }
};
