const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 员工管理列表接口文档地址：http://219.128.52.2:9999/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/user-buyer-controller/getBuyerEmployeesListUsingPOST
const web_userBuyer_find = (pParameter) => {

  if (!pParameter) pParameter = {};

  const p = {
    method: 'post',
    urlSuffix: '/web/userBuyer/find',
    data: pParameter,
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = web_userBuyer_find;
